// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-applied-js": () => import("./../../../src/pages/applied.js" /* webpackChunkName: "component---src-pages-applied-js" */),
  "component---src-pages-cafe-js": () => import("./../../../src/pages/cafe.js" /* webpackChunkName: "component---src-pages-cafe-js" */),
  "component---src-pages-development-js": () => import("./../../../src/pages/development.js" /* webpackChunkName: "component---src-pages-development-js" */),
  "component---src-pages-examwriter-js": () => import("./../../../src/pages/examwriter.js" /* webpackChunkName: "component---src-pages-examwriter-js" */),
  "component---src-pages-experian-js": () => import("./../../../src/pages/experian.js" /* webpackChunkName: "component---src-pages-experian-js" */),
  "component---src-pages-gdr-js": () => import("./../../../src/pages/gdr.js" /* webpackChunkName: "component---src-pages-gdr-js" */),
  "component---src-pages-golden-js": () => import("./../../../src/pages/golden.js" /* webpackChunkName: "component---src-pages-golden-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-misc-js": () => import("./../../../src/pages/misc.js" /* webpackChunkName: "component---src-pages-misc-js" */),
  "component---src-pages-pcf-js": () => import("./../../../src/pages/pcf.js" /* webpackChunkName: "component---src-pages-pcf-js" */),
  "component---src-pages-photography-js": () => import("./../../../src/pages/photography.js" /* webpackChunkName: "component---src-pages-photography-js" */),
  "component---src-pages-wander-js": () => import("./../../../src/pages/wander.js" /* webpackChunkName: "component---src-pages-wander-js" */)
}

